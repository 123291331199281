<template>
  <v-form class="px-5" ref="form" v-model="valid" lazy-validation>
    <div v-if="formEditMode">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formattedDate"
            label="COT Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="form.cotDate"
          :key="form.addressId"
          ref="picker"
          :max="new Date().toISOString().substr(0, 10)"
          @change="saveCotDate"
          min="1950-01-01"
          locale="en-GB"
        ></v-date-picker>
      </v-menu>
    </div>
    <div v-else>
      <v-menu
        ref="menuAdd"
        v-model="menuAdd"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedDate"
            label="COT Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          ref="picker"
          v-model="form.cotDate"
          :max="new Date().toISOString().substr(0, 10)"
          min="1950-01-01"
          @change="save"
          locale="en-GB"
        ></v-date-picker>
      </v-menu>
    </div>
    <v-text-field
      v-model="form.siteName"
      label="Site Name"
      @input="siteNameChanged"
      required
    ></v-text-field>

    <v-text-field
      v-model="form.add1"
      label="Address Line 1"
      @input="addLine1Changed"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.add2"
      label="Address Line 2"
      @input="addLine2Changed"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.town"
      label="Town/City"
      @input="townChanged"
      required
    ></v-text-field>
    <v-text-field
      v-model="form.county"
      label="County"
      @input="countyChanged"
      required
    ></v-text-field>
    <v-row class="align-center">
      <v-col cols="10">
        <v-text-field
          v-model="form.postcode"
          label="Postcode"
          placeholder="Enter Postcode"
          required
          @input="postCodeChanged"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <i
              @click="fetchPostCodeAddresses"
              v-on="on"
              v-bind="attrs"
              class="fas fa-lg fa-search-location"
              style="color: #008000; font-size: 1.4em; cursor: pointer"
            ></i>
          </template>
          <span> Find address</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-select
      v-if="showPostcode && !inCorrectPostcodeSelected"
      class="mt-6"
      :items="addresses"
      v-model="postcodeSelected"
      @change="addressSelected"
      label="Select Address"
      prepend-icon="mdi-domain"
    ></v-select>
    <div class="mt-4">
      <v-btn
        depressed
        :disabled="!valid"
        color="accent"
        @click="submitForm"
        block
        dark
        large
      >
        {{ formEditMode ? "Save changes" : "Add Address" }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import {
  mergeExistingEntriesIntoForm,
  changesSaved,
  somethingWentWrong,
} from "@/helpers/utilities"
import { mapGetters, mapActions } from "vuex"
import { getAddressState } from "@/store/helpers/default-state"
import dayjs from "dayjs"
import api from "@/store/api"

export default {
  name: "AddressInformationTab",
  data() {
    return {
      menu: false,
      menuAdd: false,
      valid: true,
      showPostcode: false,
      addresses: [],
      addressList: [],
      selectedAddressId: null,
      postcodeSelected: null,
      inCorrectPostcodeSelected: false,
      oldAdd1: "",
      oldAdd2: "",
      oldTown: "",
      oldCounty: "",
      oldPostcode: "",
      oldSiteName: "",
      form: {
        addCotDate: "",
        cotDate: "",
        siteName: "",
        add1: "",
        add2: "",
        town: "",
        county: "",
        postcode: "",
        addressId: 0,
        addressTypeId: 1,
      },
    }
  },
  computed: {
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("addresses", ["selectedAddress"]),
    ...mapGetters("company", ["companyId", "details"]),
    ...mapGetters("contacts", ["selectedContact"]),
    ...mapGetters("employee", ["userId"]),

    formattedDate() {
      if (this.form.cotDate === "" && this.form.cotDate === 0) {
        return ""
      }

      return this.form.cotDate
        ? dayjs(this.form.cotDate).format("DD/MM/YYYY")
        : ""
    },
    formattedAddDate() {
      return this.form.addCotDate
        ? dayjs(this.form.addCotDate).format("DD/MM/YYYY")
        : ""
    },
  },
  watch: {
    selectedAddress(newVal, oldVal) {
      if (newVal.addressId !== oldVal.addressId) {
        this.bindAddressDetails()
      }
    },
    formEditMode(value) {
      if (!value) {
        this.$refs.form.reset()
      } else {
        this.bindAddressDetails()
      }
    },
    // menu(val) {
    //     val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"))
    // },
  },
  created() {
    this.bindAddressDetails()
  },
  methods: {
    ...mapActions("ui", ["closeForm"]),
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("addresses", [
      "updateAddress",
      "createAddress",
      "fetchAddressesByPostcode",
      "fetchAddressesAfterRemove",
    ]),
    ...mapActions("contacts", ["fetchContacts"]),
    ...mapActions("forms", [
      "fetchNatureOfBusiness",
      "fetchTradingTypes",
      "fetchMainData",
    ]),
    ...mapActions("company", ["updateCompany", "fetchCompanyDetails"]),
    ...mapActions("contacts", ["updateContact"]),
    async fetchAddressesByContactId() {
      if (this.contactId) {
        const response = await api.get(
          `addressesByContactId/${this.contactId}`
        )
        let singleAddress = ""
        const addresses = []
        const tempAddress = response.data
        tempAddress.forEach((address) => {
          singleAddress = ""
          address.add1
            ? (singleAddress += address.add1 + ", ")
            : (singleAddress += "")
          address.add2
            ? (singleAddress += address.add2 + ", ")
            : (singleAddress += "")
          address.town
            ? (singleAddress += address.town + (address.county ? ", " : ""))
            : (singleAddress += "")
          address.county
            ? (singleAddress += address.county)
            : (singleAddress += "")

          addresses.push({ text: singleAddress, value: address.addressId })
        })
        this.addressList = addresses
      }
    },
    postCodeChanged() {
      this.form.postcode = this.form.postcode.toUpperCase()
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    siteNameChanged() {
      this.form.siteName = this.form.siteName
        .split(" ")
        .map(this.capitalize)
        .join(" ")
    },
    addLine1Changed() {
      this.form.add1 = this.form.add1.split(" ").map(this.capitalize).join(" ")
    },
    addLine2Changed() {
      this.form.add2 = this.form.add2.split(" ").map(this.capitalize).join(" ")
    },
    townChanged() {
      this.form.town = this.form.town.split(" ").map(this.capitalize).join(" ")
    },
    countyChanged() {
      this.form.county = this.form.county
        .split(" ")
        .map(this.capitalize)
        .join(" ")
    },
    async fetchPostCodeAddresses() {
      const response = await this.fetchAddressesByPostcode(this.form.postcode)
      if (response.status === 200) {
        this.inCorrectPostcodeSelected = false
        this.showPostcode = true
        this.addresses = response.addresses
      } else {
        this.inCorrectPostcodeSelected = true
        this.showPostcode = false
      }
    },
    addressSelected() {
      const splitPostCode = this.postcodeSelected.split(",")
      if (this.postcodeSelected !== "Select your address") {
        this.form.add1 = splitPostCode[0].trim()
        this.form.add2 = splitPostCode[1].trim()
        this.form.town = splitPostCode[2].trim()
        splitPostCode.length - 1 === 3
          ? (this.form.county = splitPostCode[3].trim())
          : (this.form.county = "")
      }
    },

    async submitForm() {
      this.$refs.form.validate()
      if (!this.valid) return

      const cotDate = dayjs(this.form.cotDate).unix()

      if (!this.formEditMode) {
        const noteMessage = `Address Added - ${this.form.siteName}<br>
                      ${this.form.add1}<br>
                      ${this.form.add2}<br>
                      ${this.form.town}<br>
                      ${this.form.county}<br>
      
                      ${this.form.postcode}<br>`
        const defaultAddress = getAddressState()
        const response = await this.createAddress({
          ...defaultAddress,
          ...this.form,
          noteMessage: noteMessage,
          companyId: this.companyId,
          cotDate: cotDate ? cotDate : 0,
          contactId: this.selectedContact ? this.selectedContact.contactId : 0,
          addressTypeId: this.form.addressTypeId,
          currentUserId: this.userId,
        })
        if (response && response.status === 200) {
          changesSaved("Address Successfully Added")
          this.fetchAddressesAfterRemove(this.details.companyId)
          this.fetchNotes()
          this.valid = true
          this.showPostcode = false
          this.addresses = []
          this.addressList = []
          this.selectedAddressId = null
          this.postcodeSelected = null
          this.inCorrectPostcodeSelected = false
          this.form = {
            addCotDate: "",
            cotDate: "",
            siteName: "",
            add1: "",
            add2: "",
            town: "",
            county: "",
            postcode: "",
            addressId: 0,
            addressTypeId: 1,
          }
          this.$refs.form.resetValidation()
          this.closeForm()
        } else {
          somethingWentWrong()
        }
      } else {
        const siteNameChanged =
          this.oldSiteName === this.form.siteName
            ? ""
            : `${this.oldSiteName} changed to ${this.form.siteName}<br>`
        const add1Changed =
          this.oldAdd1 === this.form.add1
            ? ""
            : `${this.oldAdd1} changed to ${this.form.add1}<br>`
        const add2Changed =
          this.oldAdd2 === this.form.add2
            ? ""
            : `${this.oldAdd2} changed to ${this.form.add2}<br>`
        const townChanged =
          this.oldTown === this.form.town
            ? ""
            : `${this.oldTown} changed to ${this.form.town}<br>`
        const countyChanged =
          this.oldCounty === this.form.county
            ? ""
            : `${this.oldCounty} changed to ${this.form.county}<br>`
        const postcodeChanged =
          this.oldPostcode === this.form.postcode
            ? ""
            : `${this.oldPostcode} changed to ${this.form.postcode}<br>`

        const noteMessage =
          siteNameChanged ||
          add1Changed ||
          add2Changed ||
          townChanged ||
          countyChanged ||
          postcodeChanged
            ? `Address Edited - ${siteNameChanged}
                      ${add1Changed}
                      ${add2Changed}
                      ${townChanged}
                      ${countyChanged}
      
                      ${postcodeChanged}`
            : ""

        const response = await this.updateAddress({
          ...this.selectedAddress,
          ...this.form,
          noteMessage: noteMessage,
          cotDate: cotDate ? cotDate : 0,
          currentUserId: this.userId,
        })
        if (response && response.status === 200) {
          changesSaved("Address Successfully Edited")
          this.fetchAddressesAfterRemove(this.details.companyId)
          this.fetchCompanyDetails()
          this.closeForm()
          this.fetchNotes()
        } else {
          somethingWentWrong()
        }
      }
    },
    bindAddressDetails() {
      if (this.formEditMode) {
        Object.entries(this.selectedAddress).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
        this.form.cotDate =
          this.form.cotDate === 0
            ? ""
            : dayjs(this.form.cotDate * 1000).format("YYYY-MM-DD")
        this.oldSiteName = this.selectedAddress.siteName
        this.oldAdd1 = this.selectedAddress.add1
        this.oldAdd2 = this.selectedAddress.add2
        this.oldTown = this.selectedAddress.town
        this.oldCounty = this.selectedAddress.county
        this.oldPostcode = this.selectedAddress.postcode
      }
    },
    validate() {
      this.$refs.form.validate()
    },
    save(date) {
      this.$refs.menuAdd.save(date)
    },
    saveCotDate(date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>
